import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../components/section/TextImage/textImage"
import WebDesignServices from "../components/section/GraphicDesignServices/graphicDesignServices"
import CallToAction from "../components/section/CallToAction/callToAction"
import { Fragment } from "react"

const WebDesignPage = () => {
    const data = useStaticQuery(graphql`
    query {
      webDesignImage1: file(relativePath: { eq: "images/sections/diseno-grafico/explica-quien-eres-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      webDesignImage2: file(relativePath: { eq: "images/sections/diseno-grafico/tu-imagen-dice-como-eres-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      webDesignImage3: file(relativePath: { eq: "images/sections/diseno-grafico/proyectos-solo-3-pasos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Diseño Gráfico"
                description="Agencia de Publicidad en Barcelona experta en diseño gráfico, branding, diseño comercial, diseño publicitario y diseño editorial."
            />

            <BannerPage
                title="Expertos en diseño gráfico"
                text="Creamos la estrategia publicitaria gráfica de tu empresa"
                color="pink"
            />

            <SectionTextImage
                title1="Explica "
                title2="¿Quién eres?"
                text={<Fragment>
                    La publicidad es hoy en día una de las mejores estrategias de marketing que tienen las empresas para <strong>explicar quiénes son y qué servicios o productos</strong> ofrecen. <br /><br />
                    El mercado actual ofrece un gran abanico de opciones para poder anunciarse. Con el <strong>diseño gráfico</strong> conseguimos crear un reflejo, una imagen de nuestra marca.
                </Fragment>}
                image={data.webDesignImage1.childImageSharp.fluid}
                imageAlt="Explica quién eres - Agencia Diseño Gráfico"
                color="pink"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Tu imagen dice "
                title2="¿Cómo eres?"
                text={<Fragment>
                    ¿Quieres que tus clientes te reconozcan con un abrir y cerrar de ojos frente a tu competencia? ¿Quieres explicar qué ofreces y qué precios tienen tus productos o servicios? El <strong>diseño gráfico</strong> es la herramienta idónea para explicar a clientes y potenciales clientes qué les puedes ofrecer. <br /><br />
                    Para ello, disponemos del <strong>branding</strong>, del <strong>diseño comercial</strong> y del <strong>diseño editorial</strong>.                </Fragment>}
                image={data.webDesignImage2.childImageSharp.fluid}
                imageAlt="Dice cómo eres - Agencia Diseño Gráfico"
                color="pink"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Proyectos "
                title2="en 3 pasos"
                text={<Fragment>
                    En IBX <strong>velamos por la efectividad y calidad</strong> de todos nuestros proyectos publicitarios. Es por eso, que establecemos un protocolo de trabajo: <br /><br />
                    <ol className="text-left">
                        <li><strong>Toma de contacto, presupuestos y timings del proyecto</strong>: Reunión con el cliente para captar sus necesidades, hacer entrega de presupuestos, plan de trabajo y fechas de entrega.</li>
                        <li><strong>Brainstorming</strong>: Meeting inicial para hacer una lluvia de ideas de todas las piezas publicitarias a realizar.</li>
                        <li><strong>Piezas publicitarias en marcha</strong>: Creación de todo el material publicitario solicitado según timing de proyecto.</li>
                    </ol>
                </Fragment>}
                image={data.webDesignImage3.childImageSharp.fluid}
                imageAlt="Proyectos gráficos en 3 pasos - Agencia Diseño Gráfico"
                color="pink"
                containerTextRight={true}
            />

            <WebDesignServices />

            <CallToAction
                color="green"
                title="¿Quieres que le demos forma a tus piezas publicitarias?"
                text={<Fragment>¡Somos tu <strong>agencia de diseño gráfico en Barcelona</strong>!</Fragment>}
                button="¿Lanzamos tu marca al mercado?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default WebDesignPage
